/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { getProjectTemplates } from 'api-lofty';
import Layout from '../layouts/Layout';
import CardTemplate from '../components/CardTemplate/cardTemplate';
// import {getProjectTemplates} from '../api/templates.api';

import '../styles/templates.css';

const useStyles = makeStyles((theme) => ({
  headerSection: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('601')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15px',
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    left: '-1.5rem',
    height: '100%',
    marginLeft: 10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyle: {
    fontWeight: 700,
    fontSize: '32px',
    color: 'rgba(20, 86, 158, 1)',
    [theme.breakpoints.down('1025')]: {
      fontSize: '24px',
    },
  },
}))

const TemplatesPage = ({ history }) => {
  const [templateData, setTemplateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const classes = useStyles();
  useEffect(async () => {
    try {
      const data = await getProjectTemplates();
      setTemplateData(data.data);
      setFilteredData(data.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const handleSearchTemplate = (e) => {
    const searchString = e;
    const templateDataTemp = templateData.filter((index) => (index.name.includes(searchString)));
    setFilteredData(templateDataTemp);
  }

  console.log(filteredData.length);

  return (
    <Layout>
      <Box style={{ backgroundColor: 'rgba(243, 243, 243, 1)', }}>
        <header className='header-template-landing-style'>
          <Box className={classes.headerSection}>
            <Box className={classes.titleStyle}>
              Plantillas
            </Box>
            <div className={classes.search}>
              <Box className={classes.searchIcon}>
                <SearchIcon />
              </Box>
              <TextField size="small" placeholder="Buscar plantilla.." classes={{ root: classes.searchTextField }} variant="outlined" onChange={(e) => handleSearchTemplate(e.target.value)} />
            </div>
          </Box>
          <div className='divisor-header-template' />
        </header>
        <main>
          <div style={{ marginTop: '50px', padding: '3rem', marginBottom: '100px' }}>
            <Grid container spacing={3}>
              {
                filteredData.length === 0 ? (
                  <Grid item md={12} style={{marginTop: '15vh', marginBottom: '15vh'}}>
                    <h2 style={{textAlign: 'center', width: '100%'}}>
                      No hay Plantillas por el momento
                    </h2>
                  </Grid>
                ) : (
                  <>
                    {
                      filteredData.map((index) => (
                        <Grid alignItems="stretch" item xl={3} lg={4} md={6} sm={12}>
                          <CardTemplate
                            name={index.name}
                            description={index?.description}
                            price={index.price}
                            url={index.slug}
                            idTemplate={index._id}
                          // onClick={() => handleClick()}
                          />
                        </Grid>
                      ))
                    }
                  </>
                )
              }
            </Grid>
          </div>
        </main>
      </Box>
    </Layout>
  );
}

export default TemplatesPage;